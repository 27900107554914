import { toast } from 'react-toastify';
import { type FieldValues, type UseFormSetError } from 'react-hook-form';

import { type ICommonError } from '@modules/types';

type ISetErrorForm = UseFormSetError<FieldValues>;

export const catchCommonError = (
  e: unknown,
  setErrorForm?: ISetErrorForm
): void => {
  const isIframe = window.self !== window.top;

  if (isIframe && window.location.pathname.includes('give-bonus')) {
    window.parent.postMessage({ data: e, type: 'ERROR_GIVE_BONUS_MODAL' }, '*');
    return;
  }

  const { data } = (e as ICommonError) ?? {};

  data?.errors?.forEach((error) => {
    setErrorForm?.(
      error?.source?.fieldPointer,
      { message: error?.title },
      { shouldFocus: true }
    );

    toast.error(
      `Unable to process your request.\n${error?.title ?? 'Please try again'}`,
      { bodyStyle: { whiteSpace: 'pre-wrap' } }
    );
  });

  if (!data?.errors?.length && data?.traceId) {
    toast.error(
      `${data?.status ?? ''}: ${data?.title ?? ''}\n${
        data?.detail ?? `Unable to process your request.\nPlease try again`
      }`,
      { bodyStyle: { whiteSpace: 'pre-wrap' } }
    );
  }
};
