import { toast } from 'react-toastify';
import { ImLink } from 'react-icons/im';
import { useTheme, type Theme } from '@mui/material/styles';
import { alpha, Tooltip, IconButton, type SxProps } from '@mui/material';
import { memo, useRef, type FC, useCallback, type MouseEvent } from 'react';

interface ICopyLinkButton {
  id: string;
  tooltipTitle: string;
  withAnimation: boolean;
  sxProps?: SxProps<Theme>;
}

const CopyLinkButton: FC<ICopyLinkButton> = ({
  id,
  sxProps = {},
  tooltipTitle,
  withAnimation,
}) => {
  const buttonRef = useRef<null | HTMLButtonElement>(null);

  const theme = useTheme();

  const animateCopyIcon = useCallback(() => {
    if (buttonRef.current) {
      buttonRef.current.classList.add('animate');
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.classList.remove('animate');
        }
      }, 300);
    }
  }, []);

  const handleCopyId = useCallback(
    (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      e.stopPropagation();
      animateCopyIcon();
      window.parent.postMessage({ data: id, type: 'copyEditId' }, '*');
      toast.success('Link is copied.');
    },
    [animateCopyIcon, id]
  );

  return (
    <Tooltip color="secondary" disableInteractive title={tooltipTitle}>
      <IconButton
        size="medium"
        color="primary"
        ref={buttonRef}
        onClick={handleCopyId}
        sx={{
          marginLeft: '0.5rem',
          position: 'relative',
          paddingTop: '0.613rem',
          display: 'inline-block',
          '&.animate .ripple': {
            animation: 'ripple-effect 0.6s ease-out',
          },
          '@keyframes ripple-effect': {
            '0%': {
              opacity: 1,
              transform: 'scale(0)',
            },
            '100%': {
              opacity: 0,
              transform: 'scale(1)',
            },
          },
          '& .ripple': {
            opacity: 0,
            width: '140%',
            height: '140%',
            top: '-0.25rem',
            left: '-0.5rem',
            borderRadius: '50%',
            position: 'absolute',
            transform: 'scale(0)',
            pointerEvents: 'none',
            backgroundColor: `${alpha(theme.palette.primary.main, 0.4)}`,
          },
          ...sxProps,
        }}
      >
        <ImLink />
        {withAnimation ? <span className="ripple" /> : null}
      </IconButton>
    </Tooltip>
  );
};

export default memo(CopyLinkButton);
