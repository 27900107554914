import dayjs from 'dayjs';
import {
  createApi,
  type FetchArgs,
  type BaseQueryApi,
} from '@reduxjs/toolkit/query/react';

import { API, ENV } from '@constants';
import replaceURLParams from '@utils/replaceURLParams';
import { baseQueryCreator } from '@utils/baseQueryCreator';
import { catchCommonError } from '@utils/catchCommonError';
import { type ISearchParams } from '@features/filters/types';
import {
  type IPlayerCodesSearch,
  type ICancelPlayerCodeParams,
} from '@modules/playerCodes/types';

interface IExtraOptions {
  useBonusService?: boolean;
}

export const playerCodesReportingServiceSlice = createApi({
  endpoints: () => ({}),
  refetchOnReconnect: true,
  reducerPath: 'playerCodesReportingService',
  baseQuery: (
    args: FetchArgs,
    api: BaseQueryApi,
    extraOptions: IExtraOptions
  ) => {
    const service = extraOptions?.useBonusService
      ? ENV.VITE_BONUS_API_URL
      : ENV.VITE_PLAYER_REPORTING_SERVICE_API_URL;

    return baseQueryCreator(service)(args, api, extraOptions);
  },
});

export const playerCodesApi = playerCodesReportingServiceSlice
  .enhanceEndpoints({
    addTagTypes: ['player-codes-list'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPlayerCodesList: builder.query<IPlayerCodesSearch, ISearchParams>({
        query: (payload) => ({
          body: payload,
          method: 'POST',
          url: API.CAMPAIGN_CODES.GRID,
        }),
      }),
      exportPlayerCodes: builder.mutation<
        {
          response: string;
          headers: Record<string, string>;
        },
        ISearchParams
      >({
        query: (payload) => ({
          body: payload,
          method: 'POST',
          url: API.CAMPAIGN_CODES.EXPORT,
          responseHandler: async (response: Response) => {
            const text = await response.text();
            const headers: Record<string, string> = {};
            response.headers.forEach((value: string, key: string) => {
              headers[key] = value;
            });
            return { headers, response: text };
          },
        }),
      }),
      cancelPlayerCode: builder.mutation<unknown, ICancelPlayerCodeParams>({
        extraOptions: { useBonusService: true },
        query: ({ id }) => ({
          method: 'POST',
          url: replaceURLParams(API.CAMPAIGN_CODES.CODE.CANCEL, {
            id,
          }),
        }),
        async onQueryStarted(
          { id, userName, searchParams },
          { dispatch, queryFulfilled }
        ) {
          try {
            await queryFulfilled;

            dispatch(
              playerCodesApi.util.updateQueryData(
                'getPlayerCodesList',
                searchParams,
                (response) => {
                  const updatedOffersList = response.data.map((row) =>
                    row.id === id
                      ? {
                          ...row,
                          status: 'CANCELLED',
                          cancelledBy: userName,
                          cancelledAt: dayjs().valueOf(),
                        }
                      : row
                  );

                  return { ...response, data: updatedOffersList };
                }
              )
            );
          } catch (e) {
            catchCommonError(e);
          }
        },
      }),
    }),
  });

export const {
  useCancelPlayerCodeMutation,
  useExportPlayerCodesMutation,
  useLazyGetPlayerCodesListQuery,
} = playerCodesApi;
