export const API = Object.freeze({
  FREE_SPINS: {
    ROOT: '/free-spins',
  },
  APP_SETTINGS: {
    ROOT: '/app-settings',
  },
  MONETARY_VALUES: {
    BONUS_RATES: '/monetary-values',
  },
  PLAYER_BONUSES_LIST: {
    GRID: '/bonuses/search',
    EXPORT: '/bonuses/export',
  },
  COMMON: {
    BRANDS: '/brands',
    CAMPAIGNS: '/campaigns',
    BONUS_TEMPLATES: '/bonus-templates',
  },
  GIVE_BONUS_MANAGEMENT: {
    ROOT: '/jwt/players/:playerId/bonuses',
    BONUS_TEMPLATES: '/jwt/bonus-templates',
    VALIDATE_PLAYER: '/jwt/players/match-brand',
  },
  CAMPAIGN_CODES: {
    GRID: '/campaigns/campaign-codes/search',
    EXPORT: '/campaigns/campaign-codes/export',
    CODE: {
      CANCEL: '/player-campaign-codes/:id/cancel',
    },
  },
  PLAYER_OFFERS: {
    GRID: '/bonus-offers/search',
    EXPORT: '/bonus-offers/export',
    OFFER: {
      CANCEL: '/bonus-offers/:id/cancel',
      DETAILS: '/bonus-offers/:id/bonus-template-snapshots',
    },
  },
  CASINO_GAMES_DATA: {
    CREATE: '/casino-games',
    SEARCH_GAME_NAME: '/casino-games/search/games',
    SEARCH_PROVIDER: '/casino-games/search/providers',
    SEARCH_GAME_TYPE: '/casino-games/search/game-types',
    SEARCH_SUB_PROVIDER: '/casino-games/search/sub-providers',
  },
  CATALOG: {
    ENUMS: '/system-values/enums',
    LOCALES: '/system-values/locales',
    COUNTRIES: '/system-values/countries',
    TIME_ZONES: '/system-values/timezones',
    CURRENCIES: '/system-values/currencies',
    RULE_ENGINE: {
      FIELDS: '/system-values/rule-engine-fields/:key',
    },
  },
  SPORTS_DATA: {
    BRANCHES: '/sports-data',
    SEARCH_EVENTS: '/sports-data/search/events',
    SEARCH_SPORTS: '/sports-data/search/sports',
    SEARCH_LEAGUES: '/sports-data/search/leagues',
    SEARCH_MARKETS: '/sports-data/search/markets',
    SEARCH_GAME_PERIODS: '/sports-data/search/game-periods',
    SEARCH_MARKET_CATEGORIES: '/sports-data/search/market-categories',
  },
  BONUS_MANAGEMENT: {
    GRID: '/bonus-templates/search',
    EXPORT: '/bonus-templates/search/export',
    BONUS_TEMPLATES: '/bonus-templates/search/match-brands',
    BONUS: {
      ROOT: '/bonus-templates/:id',
      CREATE_BONUS_TEMPLATE: '/bonus-templates',
      CHANGE_STATUS: '/bonus-templates/:id/state',
      VALIDATE_WIZARD_STEP: '/bonus-templates/partial-validation?step_id=:step',
      VALIDATE_EDIT_WIZARD_STEP:
        '/bonus-templates/:id/partial-validation?step_id=:step',
    },
  },
  CAMPAIGN_MANAGEMENT: {
    ACTIVITY_REPORT: '/campaigns/activity-report',
    MONITOR_REPORT: {
      ROOT: '/campaigns/monitor-report',
      CAMPAIGN_DECISION: '/campaigns/monitor-report/campaign-decisions/:id',
      BONUS_TEMPLATE_DECISION:
        '/campaigns/monitor-report/bonus-template-decisions/:id',
    },
    CODE_CAMPAIGNS: {
      GRID: '/campaigns/code-based/search',
      EXPORT: '/campaigns/code-based/search/export',
      CAMPAIGN: {
        ROOT: '/campaigns/code-based/:id',
        CREATE_CAMPAIGN: '/campaigns/code-based',
        CHANGE_STATUS: '/campaigns/code-based/:id/state',
        APPLICABLE_PLAYERS: '/campaigns/code-based/:id/segment/count',
        EXPORT_APPLICABLE_PLAYERS: '/campaigns/code-based/:id/segment/export',
      },
    },
    SCHEDULED_CAMPAIGNS: {
      GRID: '/campaigns/scheduled/search',
      EXPORT: '/campaigns/scheduled/search/export',
      CAMPAIGN: {
        ROOT: '/campaigns/scheduled/:id',
        CREATE_CAMPAIGN: '/campaigns/scheduled',
        CHANGE_STATUS: '/campaigns/scheduled/:id/state',
        OCCURRENCES: '/campaigns/scheduled/schedule/:id',
        APPLICABLE_PLAYERS: '/campaigns/scheduled/:id/segment/count',
        EXPORT_APPLICABLE_PLAYERS: '/campaigns/scheduled/:id/segment/export',
      },
    },
    AUTOMATIC_CAMPAIGN: {
      GRID: '/campaigns/triggered/search',
      EXPORT: '/campaigns/triggered/search/export',
      CAMPAIGN: {
        ROOT: '/campaigns/triggered/:id',
        CREATE_CAMPAIGN: '/campaigns/triggered',
        CHANGE_STATUS: '/campaigns/triggered/:id/state',
        CAMPAIGN_RELATION: '/campaigns/triggered/:id/relation-tree',
        NOTIFICATION_TEMPLATES: '/campaigns/notification-templates',
        APPLICABLE_PLAYERS: '/campaigns/triggered/:id/segment/count',
        RELATED_CAMPAIGNS: '/campaigns/triggered/search/match-brands',
        EXPORT_APPLICABLE_PLAYERS: '/campaigns/triggered/:id/segment/export',
      },
    },
  },
});
