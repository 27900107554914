import { Loader } from '@components';
import { Fade } from '@mui/material';
import { ROLE, PERMISSION } from '@constants/systemRoles';
import {
  type FC,
  useState,
  useEffect,
  useCallback,
  type ReactNode,
} from 'react';

import { ENV } from '@constants';
import { useAppDispatch } from '@hooks';
import { decodeJwt } from '@utils/jwtDecode';
import { parseSearchParams } from '@utils/parseUrlParams';
import { setCredentials } from '@features/auth/authSlice';
import { type ThemeMode } from '@features/appConfig/types';
import { setAppConfig } from '@features/appConfig/appConfigSlice';

interface IAuthProvider {
  children?: ReactNode;
}

const AuthProvider: FC<IAuthProvider> = ({ children }) => {
  const dispatch = useAppDispatch();

  const isIframe = window.self !== window.top;
  const [postMessageToken, setPostMessageToken] = useState('');

  const isValidJSON = (data: unknown): boolean => {
    if (typeof data !== 'string') {
      return false;
    }
    try {
      JSON.parse(data);
      return true;
    } catch {
      return false;
    }
  };

  const postMessageWorker = useCallback(({ data }: { data: unknown }) => {
    if (typeof data !== 'string' || !isValidJSON(data)) {
      return;
    }

    const { type, token } = JSON.parse(data);

    if (type === 'SET_CREDENTIALS' && token) {
      setPostMessageToken(token);

      window.parent.postMessage(
        JSON.stringify({ type: 'CREDENTIALS_RECEIVED' }),
        '*'
      );
    }
  }, []);

  useEffect(() => {
    if (isIframe) {
      window.addEventListener('message', postMessageWorker);
    }
    return () => {
      if (isIframe) {
        window.removeEventListener('message', postMessageWorker);
      }
    };
  }, [postMessageWorker, isIframe]);

  const queryParamToken = parseSearchParams(window.location.search, 'token');
  const theme =
    parseSearchParams(window.location.search, 'themeMode')?.toLowerCase() ??
    'light';

  let superPermissions: PERMISSION[] = [];

  const token = isIframe
    ? (postMessageToken ?? '')
    : (queryParamToken ?? ENV.VITE_DEFAULT_JWT_TOKEN ?? '');

  const {
    userType = '',
    permissions = [],
    userInfo = { username: '' },
  } = decodeJwt(token) ?? {};

  if (userType === ROLE.SUPER_ADMIN) {
    superPermissions = [...Object.entries(PERMISSION).map((p) => p[1])];
  }

  dispatch(
    setCredentials({
      token,
      userType,
      userInfo,
      permissions: [...superPermissions, ...permissions],
    })
  );
  dispatch(setAppConfig({ mode: theme as ThemeMode }));

  return isIframe && !token ? (
    <Loader />
  ) : (
    <Fade in={true} mountOnEnter unmountOnExit timeout={2000}>
      <div>{children}</div>
    </Fade>
  );
};

export default AuthProvider;
