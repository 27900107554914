import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { LuRefreshCw } from 'react-icons/lu';
import { useTheme } from '@mui/material/styles';
import { useRouteError } from 'react-router-dom';
import { Grid, Stack, Typography } from '@mui/material';

export const ErrorComponent = () => {
  const theme = useTheme();
  const routeError = useRouteError();

  useEffect(() => {
    if (routeError) {
      Sentry.captureException(routeError);
    }
  }, [routeError]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
      <Stack alignItems="center" justifyContent="center">
        <LuRefreshCw size="9rem" color={theme.palette.primary.dark} />
        <Typography variant="h2" paddingTop="4rem">
          We have got a new version ready.
        </Typography>
        <Typography variant="h2" paddingTop="1rem">
          We will do refresh for you!
        </Typography>
      </Stack>
    </Grid>
  );
};
