import { memo, type FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';

import CopyLinkButton from '@modules/common/CopyLinkButton';

interface ITitleWithClipboard {
  id: string;
  title: string;
  tooltipTitle: string;
}

const TitleWithCopy: FC<ITitleWithClipboard> = ({
  id,
  title,
  tooltipTitle,
}) => {
  const theme = useTheme();
  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography variant="h3">{title}</Typography>
      <CopyLinkButton
        id={id}
        withAnimation
        tooltipTitle={tooltipTitle}
        sxProps={{ '&:hover': { background: theme.palette.grey['50'] } }}
      />
    </Stack>
  );
};

export default memo(TitleWithCopy);
