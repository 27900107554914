import { lazy } from 'react';
import { Suspendable } from '@components';
import { type RouteObject } from 'react-router-dom';

import { ROUTES } from '@constants';
import { MODULE } from '@modules/types';

const AutomaticCampaigns = Suspendable(
  lazy(() =>
    import('@modules/automaticCampaigns/controller/index').then((module) => ({
      default: module.default,
    }))
  )
);

const Campaign = Suspendable(
  lazy(() =>
    import(
      '@modules/automaticCampaigns/submodules/campaign/controller/CampaignController'
    ).then((module) => ({
      default: module.default,
    }))
  )
);

export const AutomaticCampaignRoutes: RouteObject[] = [
  {
    path: ROUTES.AUTOMATIC_CAMPAIGNS.ROOT,
    element: <AutomaticCampaigns module={MODULE.AUTOMATIC_CAMPAIGN} />,
  },
  {
    path: ROUTES.CAMPAIGN_MANAGEMENT.ROOT,
    element: <AutomaticCampaigns module={MODULE.CAMPAIGN} />,
  },
  // TODO: Remove duplicated routes after Orbit impl
  {
    element: <Campaign mode="create" />,
    path: ROUTES.CAMPAIGN_MANAGEMENT.CREATE,
  },
  {
    element: <Campaign mode="edit" />,
    path: ROUTES.CAMPAIGN_MANAGEMENT.EDIT,
  },
  {
    element: <Campaign mode="clone" />,
    path: ROUTES.CAMPAIGN_MANAGEMENT.CLONE,
  },
  {
    element: <Campaign mode="create" />,
    path: ROUTES.AUTOMATIC_CAMPAIGNS.CREATE,
  },
  {
    element: <Campaign mode="edit" />,
    path: ROUTES.AUTOMATIC_CAMPAIGNS.EDIT,
  },
  {
    element: <Campaign mode="clone" />,
    path: ROUTES.AUTOMATIC_CAMPAIGNS.CLONE,
  },
];
