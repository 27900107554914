import { memo, type FC } from 'react';
import { useTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Stack, CircularProgress } from '@mui/material';

interface ILoader {
  variant?: 'linear' | 'circular';
}

export const Loader: FC<ILoader> = memo(({ variant = 'linear' }) => {
  const theme = useTheme();
  const isIframe = window.self !== window.top;

  if (variant === 'linear') {
    return (
      <Box
        sx={{
          ...(isIframe ? { top: '0.313rem' } : { top: 0 }),
          left: 0,
          zIndex: 2001,
          width: '100%',
          position: 'fixed',
          '& > * + *': {
            marginTop: theme.spacing(2),
          },
        }}
      >
        <LinearProgress color="primary" />
      </Box>
    );
  }

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress color="primary" />
    </Stack>
  );
});
